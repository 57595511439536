// import styles bundle

import ajax from '@fdaciuk/ajax'
import "../css/style.scss"
import Pristine from 'pristinejs';
import Swip from "./modules/swiper"
const mainSw = new Swip()

const app = {


	/*updateLazyLoad: (images) => {
		var ll = new LazyLoad({

			callback_loaded: image => {
				if (image.classList.contains("lazy--bg")) {
					setTimeout(() => {
						const src = image.currentSrc || image.src;
						const parent = image.parentNode;

						if (!parent.classList.contains("loaded")) {
							parent.classList.add("loaded");
							parent.style.backgroundImage = `url(${src})`;
						}
					}, 10);
				}
			}

		},images);
	},
    */
    getValidationConfig: () => {

        Pristine.addValidator(
            'equals',
            function (val, otherFieldSelector) {
                var other = document.querySelector(otherFieldSelector);
                return other && ((!val && !other.value) || other.value === val);
            },
            'Fields do not match',
            1,
            true
        );
      
        let config = {
            classTo: 'form__input__group',
            errorClass: 'form__input__group--error',
            successClass: 'form__input__group--success',
        };
      
        return config;
      
    },

    forms: () => {

        // Get validation config
        let config 				= app.getValidationConfig();
    
        // Get forms to validate
        let contactForms			= document.querySelectorAll('.contact__form');
    
            // Newsletter Form Validation
            if ( contactForms	) {
            [].forEach.call(contactForms, contactForm => {
                    let contactFormValidator = new Pristine(contactForm, config, true);
                    contactForm.addEventListener("submit", function (e) {
                        if ( !contactFormValidator.validate() ) {
                            e.preventDefault();
                        }
                    });
    
                });
    
            }
    
    },

    contactForm: () => {

        let config 				= app.getValidationConfig();
        let forms 		    = document.querySelectorAll('.contact__form');
    
        if ( forms ) {
    
          [].forEach.call(forms, form => {
    
            let submitBtn   = form.querySelector('.btn');
            let msg         = form.querySelector('.contact-msg--success');
            let formValidator = new Pristine(form, config, true);
    
              submitBtn.addEventListener('click', () => {
                if ( formValidator.validate()  ) {
                event.preventDefault();
                var formData = new FormData(form);
    
                formData.append('action', form.getAttribute('data-action'));
    
                let request = ajax({
                  method: 'POST',
                  headers: {
                      'content-type': false
                  },
                  url: wpp.ajax,
                  data: formData
                })
                request.then(function(response) {
                    msg.classList.add('active');
                    form.reset();
                    formValidator.reset();
                    let inputFields = form.querySelectorAll('.input');
                    [].forEach.call(inputFields, field => {
                        field.value = '';
                    });
                });
               }
    
              }, false);
    
            });
          }
      },

    showAllBtn: () => {

        let teamList = document.querySelector('.classes__filters');
    
        if (teamList) {
    
            let teamListItems = teamList.querySelectorAll('.filter__list__item input');
            let showAll = teamList.querySelector('.show-all');
    
            showAll.addEventListener('click', () => {
                showAll.classList.add('active');
                [].forEach.call(teamListItems, btn => {
                     btn.checked = false;
                });
                app.catsAjax();
            });
    
    
            }
    },

	catsAjax: () => {

        let filtersForm 		= document.querySelector('.classes__filters');
    
        if ( filtersForm ) {

            let listing 			= document.querySelector('.classes__products');
            let inputs 				= document.querySelectorAll('.classes__filters .filter-item');
 

    
            let timeout;
    
            [].forEach.call(inputs, e => {
    
                e.addEventListener('click', () => {
    
                    clearTimeout(timeout);
    
                    // Set timeout - user may choose multiple options - fire after 1s
                    timeout = setTimeout(() => {
                        let filtersFormData = new FormData(filtersForm);
           
                        var search 			= new URLSearchParams(filtersFormData);
               
                        var base 			= filtersForm.getAttribute('data-base');
                        
    
    
                        var search 			= new URLSearchParams(filtersFormData);
                        var queryString 	= search.toString();
                        if ( window.history ) {
                            window.history.pushState("", "", base + '?' + queryString);
                        }
    
                        filtersFormData.append('action', filtersForm.getAttribute('data-action'));
    
                        let request = ajax({
                                                method: 'POST',
                                                headers: {
                                                        'content-type': false
                                                },
                                                url: wpp.ajax,
                                                data: filtersFormData
                                            })
                                            request.then(function(response) {
                                                listing.innerHTML 	= response.classes_html;
                                            });
    
                    }, 100);
    
                });
    
            });
         }
    },

    mobileMenu: () => {

        let buttons = document.querySelectorAll('.header-mobile__cont .header__right__menu__item--sub .button-cat');
     
         if (buttons) {
           console.log(buttons);
           buttons.forEach(button => {
      
               button.addEventListener('click', (e) => {
                 e.preventDefault();
                 var panel = button.nextElementSibling;
                 if (panel.classList.contains("list-visible")) {
                   panel.classList.remove("list-visible");
                 } else {
                   panel.classList.add("list-visible");
                 }
               })
           });
     
     
     
         }
     
       },

    showMobileMenu: () => {
        let mobileMenu = document.querySelector('.header-mobile__cont');
        let hamburger = document.querySelector('#opener');
        
        if (hamburger) {
         
              hamburger.addEventListener('click', () => {
                mobileMenu.classList.toggle('active');
                hamburger.classList.toggle('active');
                document.body.classList.toggle('body-no-scroll');
              });
    
            }
    },

    shopSubmitFilters: () => {

		let filtersForm 		= document.querySelector('.shop__filters__form');

		if ( filtersForm ) {

			let listing 			= document.querySelector('.shop-listing-wrapper');

			let inputs        = filtersForm.querySelectorAll('input');
			let timeout;

			[].forEach.call(inputs, e => {

				e.addEventListener('change', () => {
           
					clearTimeout(timeout);

					// Set timeout - user may choose multiple options - fire after 1s
					timeout = setTimeout(() => {

						let filtersFormData = new FormData(filtersForm);
						var search 			= new URLSearchParams(filtersFormData);
						var queryString 	= search.toString();
						var base 			= filtersForm.getAttribute('data-base');

						if ( window.history ) {
							window.history.pushState("", "", base + '?' + queryString);
						}

						filtersFormData.append('action', filtersForm.getAttribute('data-action'));

						let request = ajax({
	                     	method: 'POST',
	                     	headers: {
	                        	'content-type': false
	                     	},
	                     	url: wpp.ajax,
	                     	data: filtersFormData
	                  	})
	                  	request.then(function(response) {
	                     	listing.innerHTML 			= response.products_html;

	                     	//let images = document.querySelectorAll('img.lazy');
	                     	//app.updateLazyLoad( images );

	                  	});

					}, 10);

				});

			});

		}

	},

  accordBtn: () => {

		var acc = document.getElementsByClassName("accordion");
		var i;
        if (acc) {
            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function() {
                  this.classList.toggle("active");
                  var panel = this.nextElementSibling;
                  if (panel.classList.contains("list-visible")) {
                    panel.classList.remove("list-visible");
                  } else {
                          panel.classList.add("list-visible");
                  }
                });
              }
        }
	},

  shopAccords: () => {
    let form = document.querySelector('.shop-filters-section');
    if (form) {
        var acc = form.getElementsByClassName("shop-acc");
        var i;

        if (acc) {
            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function() {
                    // Close all other accordions
                    for (var j = 0; j < acc.length; j++) {
                        if (acc[j] !== this && acc[j].classList.contains("active")) {
                            acc[j].classList.remove("active");
                            acc[j].nextElementSibling.classList.remove("list-visible");
                        }
                    }

                    // Toggle the clicked accordion
                    this.classList.toggle("active");
                    var panel = this.nextElementSibling;
                    if (panel.classList.contains("list-visible")) {
                        panel.classList.remove("list-visible");
                    } else {
                        panel.classList.add("list-visible");
                    }
                });
              }
          }
      }
  },

  reload: () => {

    document.body.addEventListener('updated_cart_totals', function () {
      location.reload(true);
  });



  },

    cartUpdateQuantity: () => {

      let updateBtn 		= document.querySelector('.update-cart');
      let input 		= document.querySelector('.product-quantity .input-text.qty');


      if ( updateBtn  ) {

        let timeout;
       

          input.addEventListener('change', () => {
      
            clearTimeout(timeout);

            // Set timeout - user may choose multiple options - fire after 1s
            timeout = setTimeout(() => {
              updateBtn.disabled = false;
              updateBtn.click();

            }, 700);

          });


      }



    },

    init: () => {
        app.forms();
		    app.catsAjax();
        app.showAllBtn();
        app.contactForm();
        app.mobileMenu();
        app.showMobileMenu();
        app.shopSubmitFilters();
        app.accordBtn();
        app.shopAccords();
	},

}


document.addEventListener("DOMContentLoaded", () => {
	app.init();

});
