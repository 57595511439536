 import Swiper from 'swiper/bundle';
  // import Swiper and modules styles

  class Swip {
    constructor() {
      if (document.querySelector(".homeSwiper")) {
        const swiper = new Swiper('.homeSwiper', {
          slidesPerView:"auto",
          spaceBetween: 30,
          slidesPerGroup: 1,
          loop: true,
          loopFillGroupWithBlank: true,
          navigation: {
            nextEl: ".btn-right",
            prevEl: ".btn-left",
          },
          });

        }}
      }

export default Swip
